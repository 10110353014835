.about-page{
  display: flex;
  flex-direction: column;
  padding:20px;
  margin-bottom: 50px;
}

.about-page .section {
  margin-bottom: 80px;
}

.about-page li {
  margin-bottom: 20px;
}