.header {
  position: fixed;
  top:0;
  background-color: white;
  width: 100vw;
}

.mobile-header {
  display: none;
}

.header li {
    list-style-type: none;
}

.header ul{
    display: flex;
    align-items:flex-end;
}

.header li {
    margin: 0 20px;
    font-size: larger;
}

.header .logo {
    font-size: 30px;
    margin-right: 80px;
    cursor: default;
}

.link-icons {
    position: fixed;
    top: 200px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
}

.main-section {
    display: flex;
    padding: 0 150px;
    margin-top: 100px;
}

.header .logo {
    font-weight: 300;
}

.mobile-header .icon-holder {
  --ggs: 2;
  position: fixed;
  top:20px;
  right: 30px;
}

.link-icons img {
    width: 50px;
}

.link-icons a {
    margin-bottom: 50px;
}

@media only screen and (max-width: 1180px) {
  .main-section {
    padding: 0 90px;
  }

  .link-icons {
    width: 90px;
  }
}

@media only screen and (max-width: 600px) {
  .main-section {
    padding: 0 20px;
  }

  .link-icons {
    flex-direction: row;
    background-color: white;
    width: 100vw;
    bottom: 0;
    height: 100px;
    top:unset;
    justify-content: center;
    left: 0;
  }

  .link-icons a {
    margin: 0 10px;
  }

  .header li:not(.logo) {
    display: none;
  }

  .mobile-header ul {
    list-style-type: none;
    margin:0;
    padding:20px;
  }

  .mobile-header {
    z-index: 3;
    position: fixed;
    right: 10px;
    top: 50px;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: white;
  }
}