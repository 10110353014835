.image-thumbnail {
  max-height: 250px;
  cursor: pointer;
}

.image-container {
  margin: 20px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}