.carousel {
  position: fixed;
  background-color:rgba(255, 255, 255, 0.8);
  width: 100vw;
  height: 100vh;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.carousel .image-container {
  display: flex;
  align-items: center;
}

.carousel .image-container i {
  font-size: 90px;
  cursor: pointer;
  z-index: 2;
}

.carousel .image {
  max-width: 700px;
  pointer-events: none;
}

.carousel .image-container .close-button {
  position: fixed;
  top:20px;
  right:20px;
  font-size: 50px;
}

.carousel .icon-holder {
  --ggs: 2;
}

.carousel .icon-holder-arrow {
  --ggs: 4;
  padding: 20px;
}

@media only screen and (max-width: 800px) {
  .carousel .image{
    flex-basis: 100%;
    display:flex;
    width: 95vw;
  }
  .carousel .image-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .carousel .image-wrapper {
    flex-basis: 100%;
    order: -1;
    display: flex;
    justify-content: center;
  }
  .carousel i {
    margin: 0 20px;
  }

  .carousel .image-container .close-button {
    top:20px;
    right:0px;
  }
}

@media only screen and (max-width: 600px) {
  .carousel {
    background-color: white;
  }
}